import React, { useContext, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import navigate from '../utils/navigate'
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'

export default function New_Leave_Request_step1() {
  const { Member, stepOne } = useContext(LeaveContext)

  const [state, setState] = useState({
    FirstName: '',
    LastName: '',
    EmployeeNumber: '',
    DOH: '',
    DOB: '',
    StreetAddress: '',
    CityAddress: '',
    StateID: '',
    StateList: [],
    ZipAddress: '',
    Error: {},
  })

  useEffect(() => {
    if (!Member) {
      navigate('/Overview/');
    } else {
      setState(prev => ({
        ...prev,
        FirstName: Member.FirstName,
        LastName: Member.LastName,
        EmployeeNumber: Member.EmployeeNumber,
        DOH: Member.DOH,
        DOB: Member.DOB,
        StreetAddress: Member.StreetAddress,
        CityAddress: Member.CityAddress,
        StateID: Member.StateID,
        StateList: Member.StateList,
        ZipAddress: Member.ZipAddress,
        Error: {},
      }))
    }
  }, [Member])

  const onSubmit = (event) => {
    event.preventDefault()

    const Errors = {}
    // Validate Data
    if (state.StreetAddress.length < 2) Errors.StreetAddress = 'Street is not valid'

    if (state.CityAddress.length < 2) Errors.CityAddress = 'City is not valid'

    if (!state.StateID) Errors.StateID = 'State is not valid'

    if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(state.ZipAddress)) Errors.ZipAddress = 'ZipCode is not valid'

    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // remove errors before saving to the store
    const newMemberData = { ...state }
    delete newMemberData.Error

    // save to the store
    stepOne(newMemberData)
    navigate('/New_Leave_Request_step2/');
  }
  const handleStreetChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.StreetAddress
    setState(prev => ({ ...prev, StreetAddress: e.target.value, Error }))
  }
  const handleCityChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.CityAddress
    setState(prev => ({ ...prev, CityAddress: e.target.value, Error }))
  }
  const handleStateChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.StateID
    setState(prev => ({ ...prev, StateID: e.target.value, Error }))
  }
  const handleZipChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.ZipAddress
    setState(prev => ({ ...prev, ZipAddress: e.target.value, Error }))
  }
  const getAnimationStyle = delay => ({ animation: `0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) ${0.1 + (delay / 10)}s 1 both fadeIn` })

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Leave Request Step 1" />
        <div className="content-outline-998 leaveForm">
          <ul className="steps">
            <li
              className="present"
              style={{ marginLeft: -2, paddingLeft: '1.75em', width: '7.75em' }}
            >
              <span>
                <strong>Step 1</strong>
                PERSONAL INFO
              </span>
            </li>
            <li className="future" style={{ width: '7.75em' }}>
              <span>
                <strong>Step 2</strong>
                MANAGER INFO
              </span>
              <i />
            </li>
            <li className="future" style={{ width: '7em' }}>
              <span>
                <strong>Step 3</strong>
                CONTACT INFO
              </span>
              <i />
            </li>
            <li className="future" style={{ width: '8.5em' }}>
              <span>
                <strong>Step 4</strong> REQUEST DETAILS
              </span>
              <i />
            </li>
            <li className="future" style={{ width: '11.75em' }}>
              <span>
                <strong>Step 5</strong>
                ACKNOWLEDGMENTS
              </span>
              <i />
            </li>
          </ul>
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            To save time we’ve pulled your personal information from our
            files. <br />
            Please review and correct any outdated information below.
          </div>
          <form
            className="LeaveFormEntry"
            onSubmit={onSubmit}
          >
            <div className="formDiv" style={getAnimationStyle(0)}>
              First Name:&nbsp;&nbsp;
              <span
                name="ctl00$Holder$Request01$txtFirstName"
                id="ctl00_Holder_Request01_txtFirstName"
                className="color3"
              >
                {state.FirstName}
              </span>
            </div>
            <div className="formDiv" style={getAnimationStyle(1)}>
              Last Name:&nbsp;&nbsp;
              <span
                className="color3"
                name="ctl00$Holder$Request01$txtLastName"
                id="ctl00_Holder_Request01_txtLastName"
              >
                {state.LastName}
              </span>
            </div>
            <br className="clear" />
            <div className="formDiv" style={getAnimationStyle(2)}>
              Date of Birth:&nbsp;&nbsp;
              <span
                className="color3"
                name="ctl00$Holder$Request01$txtDOB"
                id="ctl00_Holder_Request01_txtDOB"
              >
                {state.DOB}
              </span>
            </div>
            <br className="clear" />
            <div className="formDiv" style={getAnimationStyle(3)}>
              Date of Hire:&nbsp;&nbsp;
              <span
                className="color3"
                name="ctl00$Holder$Request01$txtDOH"
                id="ctl00_Holder_Request01_txtDOH"
              >
                {state.DOH}
              </span>
            </div>
            <div className="formDiv" style={getAnimationStyle(4)}>
              Employee ID#&nbsp;&nbsp;
              <span
                className="color3"
                name="ctl00$Holder$Request01$txtEmployeeID"
                id="ctl00_Holder_Request01_txtEmployeeID"
              >
                {state.EmployeeNumber}
              </span>
            </div>
            <br className="clear" />
            <div className={state.Error.StreetAddress ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(5)}>
              <label>Street Address:&nbsp;&nbsp;</label>
              <input
                type="text"
                name="ctl00$Holder$Request01$txtAddress"
                id="ctl00_Holder_Request01_txtAddress"
                value={state.StreetAddress}
                onChange={handleStreetChange}
              />
            </div>
            <div className={state.Error.CityAddress ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(6)}>
              <label htmlFor="ctl00_Holder_Request01_txtCity">City:&nbsp;&nbsp;</label>
              <input
                type="text"
                name="ctl00$Holder$Request01$txtCity"
                id="ctl00_Holder_Request01_txtCity"
                value={state.CityAddress}
                onChange={handleCityChange}
              />
            </div>
            <br className="clear" />
            <div className={state.Error.StateID ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(7)}>
              <label>State:&nbsp;&nbsp;</label>
              <select
                name="ctl00$Holder$Request01$ddlState"
                id="ctl00_Holder_Request01_ddlState"
                style={{ width: '4em' }}
                onChange={handleStateChange}
                value={state.StateID}
              >
                <option value="">State</option>
                {state.StateList.map(state => <option key={state.Name} value={state.Value}>{state.Name}</option>)}
              </select>
            </div>
            <div className={state.Error.ZipAddress ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(8)}>
              <label>Zip Code:&nbsp;&nbsp;</label>
              <InputMask
                mask="99999"
                maskChar=""
                type="text"
                name="ctl00$Holder$Request01$txtZip"
                id="ctl00_Holder_Request01_txtZip"
                value={state.ZipAddress}
                style={{ width: '3.5em' }}
                onChange={handleZipChange}
              />
            </div>
            <div className="errorMsgs">
              {Object.keys(state.Error).map(x => <span key={state.Error[x]}>{state.Error[x]}<br /></span>)}
            </div>
            <p className="clear">&nbsp;</p>
            <input
              type="submit"
              id="setp1submit"
              value="Continue &raquo;"
            />
          </form>
        </div>
        <div className="whiteMask" />
      </Layout>
    </PrivateRoute>
  )
}
